import React from 'react';
import logo from '../assets/logo.png';

export default class AppHeader extends React.Component {
    render(){
        return(
            <div className="container my-3 app-header">
                <div className="logo">
                    <img src={logo} alt="ExoPlanets" title="ExoPlanets" className="img-fluid" />
                </div>
            </div>
        );
    }
}