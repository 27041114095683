import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import preloader from './assets/preloader.gif';
import './Animation.scss';
import './App.scss';
import './AppMediaQueries.scss';
import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';

// Website Constants
const Home = lazy(() => import('./components/Home'));

function App() {
  return (
    <div className="root-container">
      <Router>
        <Suspense fallback={<div className="container text-center main-content"><p className="my-4 py-4 text-center"><img height="24" src={preloader} alt="Loading" /> Loading...</p></div>}>
          <AppHeader />
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
          <AppFooter />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
