import React from 'react';

export default class AppFooter extends React.Component {
    render(){
        return(
            <div className="container text-center footer-text my-3">
                <span className="badge badge-dark">&copy; 2020 EXOPLANETS by ~ <i className="fa fa-user mr-2" aria-hidden="true"></i>NiTiN</span>
            </div>
        );
    }
}